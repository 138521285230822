<template>
  <div class="tw-px-6">
    <v-row>
      <v-col cols="12" class="tw-mt-4 tw-mb-8">
        <h3
          class="tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-2"
        >
          Add a new note
        </h3>
        <p class="tw-text-left tw-text-light-grey">Instructions</p>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="save">
            <validation-provider v-slot="{ errors }">
              <j-text-field
                color="secondary"
                v-model="note.title"
                type="text"
                :error-messages="errors"
                label="Note Title*"
                class="tw-mt-6"
                customClasses="tw-text-left"
                placeholder="Give your note a helpful title"
              />
            </validation-provider>
            <span class="j-text-field-label tw-my-5">Content*</span>
            <!-- Integrate Quill Editor -->
            <vue-editor
              v-model="note.body"
              ref="editor"
              :editor-toolbar="customToolbar"
            ></vue-editor>
            <!-- End of Quill Editor -->
            <span class="tw-hidden">{{ invalid }}</span>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { VueEditor } from "vue2-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "NoteCreate",
  components: {
    VueEditor
  },

  data() {
    return {
      note: {
        title: "",
        tripId: this.$route.params.id,
        body: "<h1>New note</h1>"
      },
      customToolbar: [
        [{ header: [1, 2, 3, 4, false] }],
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }]
      ]
      // More quill editor options
      /*[{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{ size: [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check'}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']*/
    };
  },

  watch: {
    enable: function (val) {
      if (val) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    }
  },

  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    enable() {
      return this.note.title.length > 0 && this.note.body.length > 0;
    },
    sessionUser() {
      return this.$store.state.auth.user
    }
  },

  methods: {
    async save() {
      try {
        await this.$store.dispatch("note/create", {
          ...this.note,
          lastEditedBy: this.sessionUser.id,
        });

        this.note.title = "";
        this.note.body = "<h1>New note</h1>";
        this.$router.push({ name: "NotesListRoute" });
      } catch (err) {
        console.error("Error creating note", err);
      }

      this.$router.push({ name: "NotesListRoute" });
    }
  },
  async beforeMount() {
    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation,note"
        }
      }
    ]);

    if (!this.trip.isPremium && this.trip.notes.length > 0) {
      this.$router.push({ name: "NotesListRoute" });
    }

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Notes",
      bgColor: "#56CBFE",
      fontColor: "#fff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: true,
      text: "Save",
      isDisabled: true,
      method: this.save,
      bgColor: "tw-bg-chartreuse"
    });
  },
  destroyed() {
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });
  }
};
</script>

<style lang="scss" scoped>
.ql-toolbar {
  border-radius: 4px;
  border: 1px solid #f5f5f5 !important;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
}

.ql-toolbar.ql-snow .ql-formats {
  text-align: left;
  margin-right: 0 !important;
  border-right: 1px solid lightgrey;

  &:last-child {
    border-right: 0;
  }

  @media screen and (min-width: 1023px) {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.ql-container {
  border: none !important;
  border-radius: 8px;
  background: #f2f2f2;
  margin-top: 20px;
}

.ql-editor ul[data-checked="true"] li,
.ql-editor ul[data-checked="false"] li {
  display: flex;
  @apply tw-font-semibold;
  overflow-wrap: anywhere;
  white-space: normal;
}

.ql-editor ul[data-checked="true"] > li::before,
.ql-editor ul[data-checked="false"] > li::before {
  margin-left: -45px;
  margin-bottom: 16px;
  margin-top: -2px;
}

.ql-editor ul[data-checked="false"] > li:before {
  content: "";
  display: inline-block;
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 5px;
}

.ql-editor ul[data-checked="true"] > li:before {
  content: "";
  display: inline-block;
  width: 22px;
  min-width: 22px;
  height: 22px;
  background-color: #56cbfe;
  color: white;
  text-align: center;
  border-radius: 50%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><circle cx="12" cy="12" r="12" fill="%2356cbfe"/><polyline points="6 12 10 16 18 8" stroke="white" stroke-width="2" fill="none"/></svg>');
  background-size: cover;
  font-size: 15px;
  line-height: 19px;
  margin-right: 5px;
}
</style>
